import React from "react";
import { roundOff } from "utils/Utils";
import module_style from "../../Style.module.css";
import EditIcon from "@material-ui/icons/Edit";
import { is_empty } from "utils/validations";
import { getFilterConfig, getTableConfig } from "./TableConfig";
import ExlyTable from "../../../../../common/Components/ExlyTable";
import { RenderAvgRating } from "../../components/TableFields/TableFields";
import { MobileModal } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import api from "data/APIs";
import FeedbackForm from "../../components/FeedbackForm";
import ExlyLoader from "ui/modules/ExlyLoader";
import { useListController } from "react-admin";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import ShareIcon from "@material-ui/icons/Share";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Share from "ui/modules/Share";
import { useNotifications } from "utils/hooks";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { tableChipsFormatter } from "../../common/tableConfig";
import StatsComponent from "../../components/StatsComponent";
import { useFeedbacks } from "../../data/useFeedbacks";
import { EXLY_FEEDBACKS_MORE_HREFS, TABS } from "../../data/module_constants";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import useTabsConfig from "../../data/useTabsConfig";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { OfferingFeedbackSettingsBtn } from "features/ManageOfferings/modules/OfferingFeedback/modules/OfferingFeedbackSettingsBtn/OfferingFeedbackSettingsBtn";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";

const ManageFeedbacks = (props) => {
  const {
    layoutDesktopMargin,
    titleClassname,
    hideFilters,
    showSettings,
    emptyStateOnClick,
    isManageOfferings
  } = props;
  const isDesktop = useIsDesktop();
  const { ratings, processing, fetchRatings, getFeedbackQuestions } =
    useFeedbacks(props);
  const { tabConfig } = useTabsConfig({
    ...props,
    active_tab: TABS["manage-feedback-forms"].value,
  });
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.FEEDBACKS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const { loading } = useListController(props);

  React.useEffect(() => {
    fetchRatings();
  }, []);

  const { pushNotification } = useNotifications();
  const [share_data, set_share_data] = React.useState(null);
  const [listings] = React.useState(null);
  const [show_modal, set_show_modal] = React.useState(false);
  const [show_more_modal, set_show_more_modal] = React.useState(false);
  const [selected_record, set_selected_record] = React.useState(null);

  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.FEEDBACKS
  );

  const hostSiteURL = React.useMemo(() => getCreatorHostsiteURL()?.url, []);

  const renderFeedbackLink = ({ record = {}, source }) => {
    const link = `${hostSiteURL}/feedback/${record[source]}`;
    return (
      <>
        <div
          style={isDesktop ? { maxWidth: 350 } : {}}
          className={`row no-gutters`}
        >
          <a
            style={{ maxWidth: 280 }}
            className={`mr-2 line_max_1`}
            target="_blank"
            href={link}
            rel="noreferrer"
          >
            {link}
          </a>
          {isDesktop && (
            <FileCopyOutlined
              style={{ fill: "#4A3AB1", cursor: "pointer" }}
              onClick={() => handleCopyLink(link)}
            />
          )}
          {isDesktop && (
            <ShareIcon
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={() => set_share_data(link)}
            />
          )}
        </div>
      </>
    );
  };

  const handleCopyLink = (link) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(link);
      pushNotification("Link Copied!", {
        variant: "outlined",
        color: "primary",
      });
    }
  };

  const renderActions = (props) => {
    const { record = {} } = props;
    let color;
    if (!is_empty(record.avg_rating)) {
      const overall_rating = roundOff(record.avg_rating, 2);
      const feedback_questions = getFeedbackQuestions();
      const ratings = feedback_questions?.data?.data?.rating;
      color = !is_empty(ratings)
        ? ratings[parseInt(overall_rating) - 1]?.colour
        : "";
    }

    return (
      <EditIcon
        style={{
          fill: !is_empty(color) && !isDesktop ? color : "",
          cursor: HAS_WRITE_ACCESS ? "pointer" : "not-allowed",
        }}
        onClick={() => {
          if (!HAS_WRITE_ACCESS) return;
          set_selected_record(record);
          set_show_modal(true);
        }}
      />
    );
  };

  const FilteredEmptyState = () => {
    return (
      <div className="text-center py-5 text-muted">
        <div>No Feedback found!</div>
      </div>
    );
  };

  if (processing || loading) return <ExlyLoader />;

  return (
    <>
      <ExlyTable
        ra_props={props}
        tabConfig={tabConfig}
        {...(!showSettings && { tableTitle: "Feedback" })}
        selectedTab={TABS["manage-feedback-forms"].value}
        columnConfig={getTableConfig(renderActions, renderFeedbackLink)}
        {...(!hideFilters ? { tableFilters: getFilterConfig() } : {})}
        tableChipsFormatter={tableChipsFormatter(ratings, listings)}
        filteredEmptyState={<FilteredEmptyState />}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: showSettings ? "168px" : "68px",
          layoutDesktopMargin: layoutDesktopMargin ?? "20px 24px",
          noMobileBoxShadow: true,
          headerClassName: "mb-1",
          description: (
            <>
              Host events and receive feedback from your customers. All the
              collected feedback will be listed here.
              <StatsComponent />
            </>
          ),
          titleClassname: titleClassname,
          learnMoreHref: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK,
          secondaryComponent: isDesktop && !isManageOfferings ? (
            <LearnMoreCta href={EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK} />
          ) : null,
          descriptionClassName: "w-100",
        }}
        {...(showSettings && {
          footerProps: {
            classes: {
              footer: "px-3 pt-3 !tw-h-auto",
              mobileWrapper: " !tw-bottom-[99px]",
            },
            showPrimaryBtn: true,
            primaryBtnJSX: <OfferingFeedbackSettingsBtn fullWidth />,
          },
          hasMobileFooter: true,
        })}
        borderedFields={true}
        fieldsLeftPadded={true}
        fieldsAlignment="space-between"
        recordFooterVariant="secondary"
        getRecordFooterClassName={(record) =>
          classnames(
            module_style.mobile_footer,
            record.avg_rating &&
              module_style[`mobile_rating_${parseInt(record.avg_rating)}`]
          )
        }
        renderSecondaryAction={(record) => {
          let color = "#6c5dd3";
          if (!is_empty(record.avg_rating)) {
            const overall_rating = roundOff(record.avg_rating, 2);
            const feedback_questions = getFeedbackQuestions();
            const ratings = feedback_questions?.data?.data?.rating;
            color = ratings[parseInt(overall_rating) - 1].colour;
          }

          return (
            <div
              style={{ color: color }}
              className={`row no-gutters align-items-center ${module_style.mobile_secondary_action}`}
            >
              <span style={{ fontSize: 14 }}>Rating:</span>{" "}
              <RenderAvgRating record={record} source="avg_rating" />
            </div>
          );
        }}
        customPrimaryAction={{
          label: "More",
          onClick: (row) => {
            set_selected_record(row);
            set_show_more_modal(true);
          },
          onClose: (row, rows, setDrawerConfig, setIsMoreActionsDrawerOpen) => {
            set_selected_record(null);
            setIsMoreActionsDrawerOpen(false);
          },
        }}
        primaryKey="id"
        noExportButton
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "No Feedback Received Yet.",
          description:
            "Host events and receive feedback from your customers. All the collected feedback will be listed here. View the video to learn more.",
          primaryCtaProps: HAS_WRITE_ACCESS && {
            title: "Manage Feedback Form",
            onClick:
              emptyStateOnClick ??
              (() =>
                (window.location.href = `${window.location.origin}/#/${api.feedbacks.fetch_listing_feedbacks}`)),
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK,
          },
          videoSrc: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK_VIDEO,
          playerProps: { playing: !isFeatureFlagLoading && !isFeatureVisited }, // autoplay the knowledge base video on the first visit to this feature
          className: "mt-3",
        }}
      />

      {!is_empty(selected_record) && show_modal && (
        <FeedbackForm
          selected_record={selected_record}
          show_modal={show_modal}
          set_show_modal={(value) => set_show_modal(value)}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!is_empty(share_data)}
        onClose={() => set_share_data(null)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Share
          toast={(text) => {
            pushNotification(text, { variant: "outlined", color: "primary" });
          }}
          header={"Share feedback link"}
          link={share_data}
          share_text={`Thank you for booking! Could you help me out and fill this form soon? Give ratings and suggest on how I can improve. ${share_data}`}
          setShowShare={() => {
            set_share_data(null);
          }}
        />
      </Modal>

      <MobileModal
        open={show_more_modal}
        onClose={() => set_show_more_modal(false)}
        customFooter={<></>}
        customHeader={<></>}
      >
        <div className="m-2">
          {HAS_WRITE_ACCESS && (
            <div
              onClick={() => {
                set_show_modal(true);
                set_show_more_modal(false);
              }}
              className="mb-1"
            >
              <span className="mr-1">
                <EditIcon />
              </span>
              Edit Feedback Form{" "}
            </div>
          )}

          <div
            onClick={() => {
              const link = `${hostSiteURL}/feedback/${selected_record.listing_uuid}`;
              handleCopyLink(link);
              set_show_more_modal(false);
            }}
            className="mb-1"
          >
            <span className="mr-1">
              <FileCopyOutlined />
            </span>
            Copy Feedback Form Link
          </div>

          <div
            onClick={() => {
              const link = `${hostSiteURL}/feedback/${selected_record.listing_uuid}`;
              set_share_data(link);
              set_show_more_modal(false);
            }}
          >
            <span className="mr-1">
              <ShareIcon />
            </span>{" "}
            Share Feedback Form Link
          </div>
        </div>
      </MobileModal>
    </>
  );
};

export default withComponentLibraryTheme(ManageFeedbacks);
