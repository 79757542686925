import React from "react";
import { ImageUploader } from "ui/pages/TemplatePreview/widgets";
import module_style from "../../Style.module.css";
import placeholderImg from "assets/images/image-uploader-placeholder.png";
import { Input, Textarea } from "@my-scoot/component-library-legacy";
import Rating from "react-rating";
import StarsRoundedIcon from "@material-ui/icons/StarRounded";
import EmptyStar from "@material-ui/icons/StarBorderRounded";
import { is_empty } from "utils/validations";
import { useFeedbacks } from "../../data/useFeedbacks";
import ExlyLoader from "ui/modules/ExlyLoader";
import { useDispatch } from "react-redux";
import { EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE } from "../../redux/actions";
import ErrorHelperText from "common/Components/ErrorHelperText/ErrorHelperText";
import { ExlyOfferingSelect } from "common/form";
import { OFFERING_STATUSES } from "constants/schedule";
import { useParams } from "react-router-dom";

const ReviewForm = (props) => {
  const {
    offerings_processing,
    active_offerings,
    error_object,
    show_offerings,
    review_payload,
    updateData,
  } = useFeedbacks(props);
  const dispatch = useDispatch();
  const { listing_uuid } = useParams();

  const selected_offering =
    !is_empty(review_payload?.listing_uuid) && !is_empty(active_offerings)
      ? active_offerings.find(
          (item) => item.id === review_payload?.listing_uuid
        )
      : null;

  const handleListingChange = () => {
    dispatch({
      type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
      payload: {
        key: "show_offerings",
        value: true,
      },
    });
  };

  return (
    <>
      {show_offerings && !listing_uuid ? (
        <div className={`my-4 mx-4`}>
          {is_empty(active_offerings) ? (
            <ExlyLoader />
          ) : (
            <>
              <div className={`${module_style.offering_label}`}>
                Select an offering
              </div>
              <div
                className={`mb-2 ${module_style.input_label} font-weight-light mt-2`}
              >
                Choose the offering for which you’d like to add review
              </div>

              <ExlyOfferingSelect
                disabled={offerings_processing}
                value={review_payload?.listing_uuid}
                placeholder="Select one"
                showStatus
                fullWidth
                onChange={(uuid) => {
                  if (is_empty(uuid)) return;
                  updateData(uuid, "listing_uuid");
                }}
                offering_status={OFFERING_STATUSES.live.key}
              />
            </>
          )}
        </div>
      ) : (
        <>
          {!is_empty(selected_offering) && !listing_uuid && (
            <div
              className={`d-flex px-4 py-2 justify-content-between align-items-center ${module_style.selected_offering}`}
            >
              <span>{selected_offering?.label}</span>

              <span
                onClick={handleListingChange}
                className={`action_text font-normal`}
              >
                Change
              </span>
            </div>
          )}
          <div className={`my-4 mx-4`}>
            <div className={`${module_style.input_label}`}>Customer photo</div>
            <div className={`d-flex mt-2 align-items-center`}>
              <div>
                <ImageUploader
                  hideRecommendedText
                  uploadUrl={review_payload?.image}
                  setUploadUrl={(firebaseUrl) => {
                    updateData(firebaseUrl, "image");
                  }}
                  fullWidth={600}
                  hoverText="Upload Image"
                  width={84}
                  height={84}
                  customDefaultImage={placeholderImg}
                  borderRadius="50%"
                  query={"customer"}
                  id="publish_feedback_customer_image"
                />
                {error_object?.image && (
                  <ErrorHelperText
                    text={"Required"}
                    classes={{ wrapper: "mt-2" }}
                  />
                )}
              </div>

              <div
                className={`ml-4 small font-weight-light ${module_style.image_helper_text}`}
              >
                Recommended format:
                <br />
                150 x 150 pixels (PNG or JPG)
              </div>
            </div>

            <div className={`${module_style.input_label} mt-3 mb-1`}>
              Customer name<span className="text-danger">*</span>
            </div>
            <Input
              error={error_object?.name}
              helperText={error_object?.name ? "Required" : null}
              fullWidth
              value={review_payload?.name}
              onChange={(e) => {
                let value = e.target.value;
                updateData(value, "name");
              }}
            />

            <div className={`${module_style.input_label} mt-3 mb-1`}>
              Caption
            </div>
            <Input
              error={error_object?.sub_heading}
              helperText={error_object?.sub_heading ? "Required" : null}
              fullWidth
              value={review_payload?.sub_heading}
              onChange={(e) => {
                let value = e.target.value;
                updateData(value, "sub_heading");
              }}
            />

            <div className={`${module_style.input_label} mt-3 mb-1`}>
              Feedback<span className="text-danger">*</span>
            </div>
            <Textarea
              className={`${module_style.input_text_area}`}
              error={error_object?.description}
              helperText={error_object?.description ? "Required" : null}
              fullWidth
              placeholder="Type in a custom user review"
              value={review_payload?.description}
              onChange={(e) => {
                let value = e.target.value;
                updateData(value, "description");
              }}
            />

            <div className={`${module_style.input_label} mt-3 mb-1`}>
              Rating from customer
            </div>

            <Rating
              placeholderRating={review_payload?.rating}
              onChange={(value) => {
                updateData(value, "rating");
              }}
              emptySymbol={
                <EmptyStar className={`${module_style.input_star}`} />
              }
              placeholderSymbol={
                <StarsRoundedIcon className={`${module_style.input_star}`} />
              }
              fullSymbol={
                <StarsRoundedIcon className={`${module_style.input_star}`} />
              }
            />
            {review_payload?.rating > 0 && (
              <div
                onClick={() => updateData(0, "rating")}
                className={`text-danger pointer small mt-2`}
              >
                Remove
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ReviewForm;
