import { integration_key__e_invoice_customer } from "features/Integration/constants/Integration.constants";
import React from "react";

export const featuresHeading = {
  custom_code: "Unlock Custom Code Functionality",
  affiliate_marketing: "Unlock Affiliate Marketing",
  creatives:
    "Use professional Creatives to drive better Ad Performance and Impactful Business decisions",
  certificates:
    "Unlock Professional Certificates for your users and allow them to showcase their competency",
  organisation:
    "Unlock Multi-fold Growth opportunities when working with a team",
  whatsapp_blast: (
    <span>
      <span className="butterscotch_yellow">40%</span> of our creators have
      unlocked <span className="butterscotch_yellow">5x</span> growth with
      WhatsApp Broadcast
    </span>
  ),
  refund_history: "Initiate Customer Refunds Effortlessly",
  [integration_key__e_invoice_customer]: "E-invoices (powered by ClearTax)",
};

export const featuresDescription = {
  affiliate_marketing:
    "Enable Affiliate Marketing to grow your reach by rewarding partners for every successful referral",
  custom_code:
    "Unlock the Custom Code functionality to elevate your pages with your unique touch.",
  creatives: "Design & Download beautiful creatives for your social media",
  certificates:
    "Create & Share Certificates with your clients who have completed your courses or workshops",
  organisation:
    "Upgrade Now to assign different roles and access to different organisation members and increase your efficiency",
  whatsapp_blast:
    "Broadcast WhatsApp messages curated for your Leads and Customers, to increase your sells.",
  refund_history:
    "Upgrade to easily process refunds and keep your customers satisfied with seamless transactions.",
  [integration_key__e_invoice_customer]:
    "Upgrade to automate GST-compliant invoice generation.",
};
