import { POST_TYPE_IDS_ENUM } from "@my-scoot/exly-react-component-lib/build/cjs/constants/BrandedCommunity/Post";
import { BRANDED_COMMUNITY_QUERY_PARAMS } from "features/BrandedCommunity/constants/BrandedCommunity.constants";

export { POST_TYPE_IDS_ENUM };

export const default_post_type_id = POST_TYPE_IDS_ENUM.TEXT;

export const post_content_type_ids_to_show_list = [
  POST_TYPE_IDS_ENUM.IMAGE,
  POST_TYPE_IDS_ENUM.OFFERING,
  POST_TYPE_IDS_ENUM.POLL,
  POST_TYPE_IDS_ENUM.VIDEO,
  POST_TYPE_IDS_ENUM.DOCUMENT,
];

export const DEFAULT_SECTIONS = {
  FEED: 1,
  MEMBERS: 2,
  BONUS_OFFERINGS: 3,
  DIRECT_MESSAGES: 4,
  POST_PREVIEW_PANEL: 5,
};

export const DEFAULT_SECTIONS_MAP = {
  [DEFAULT_SECTIONS.FEED]: { id: DEFAULT_SECTIONS.FEED },
  [DEFAULT_SECTIONS.MEMBERS]: { id: DEFAULT_SECTIONS.MEMBERS },
  [DEFAULT_SECTIONS.BONUS_OFFERINGS]: { id: DEFAULT_SECTIONS.BONUS_OFFERINGS },
  [DEFAULT_SECTIONS.DIRECT_MESSAGES]: { id: DEFAULT_SECTIONS.DIRECT_MESSAGES },
};

export const COMMUNITY_URL_QUERY_PARAMS = BRANDED_COMMUNITY_QUERY_PARAMS;

export const default_sections_keys_in_listing_details = {
  [DEFAULT_SECTIONS.FEED]: "feed",
  [DEFAULT_SECTIONS.MEMBERS]: "members",
  [DEFAULT_SECTIONS.BONUS_OFFERINGS]: "bonus_offerings",
  [DEFAULT_SECTIONS.DIRECT_MESSAGES]: "direct_messages",
};

export const DEFAULT_SECTIONS_DEFAULT_LABELS = {
  [DEFAULT_SECTIONS.FEED]: "Feed",
  [DEFAULT_SECTIONS.MEMBERS]: "Members",
  [DEFAULT_SECTIONS.BONUS_OFFERINGS]: "Bonus Offerings",
  [DEFAULT_SECTIONS.DIRECT_MESSAGES]: "Direct Messages",
};
