// Third party imports
import React from "react";
import ActionFieldCustom from "ui/Fields/ActionFieldCustom";
import MoreActions from "../../components/MoreActions";
import {
  RatingField,
  RemarksField,
} from "../../components/TableFields/TableFields";

// Components
import { module_constants } from "../../data/module_constants";

export const getTableConfig = (ratings) => [
  {
    field: "customer_name",
    headerName: module_constants.customer_name,
    sortable: false,
    isPrimary: true,
    emptyField: "N/A",
    avatar: true,
  },
  {
    field: "listing_title",
    headerName: module_constants.listing_title,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "rating",
    headerName: module_constants.rating,
    valueFormatter: (record) => (
      <RatingField record={record} source="rating" ratings={ratings} />
    ),
    sortable: false,
    hidden: true,
  },
  {
    field: "feedback_data",
    headerName: module_constants.remark,
    valueFormatter: (record) => (
      <RemarksField record={record} ratings={ratings} />
    ),
    sortable: false,
    hidden: true,
  },
  {
    field: "feedback_data",
    headerName: "Question",
    valueFormatter: (record) => (
      <RemarksField record={record} ratings={ratings} type="question" />
    ),
    hiddenDesktopColumn: true,
  },
  {
    field: "feedback_data",
    headerName: "Answer",
    valueFormatter: (record) => (
      <RemarksField record={record} ratings={ratings} type="answer" />
    ),
    hiddenDesktopColumn: true,
  },
  {
    field: "",
    headerName: module_constants.action,
    valueFormatter: (record) => (
      <ActionFieldCustom
        record={record}
        sortable={false}
        label={module_constants.action}
        emptyText={"N/A"}
        ActionField={MoreActions}
        Nobutton={true}
      />
    ),
    sortable: false,
    hidden: true,
    fixed: "right",
  },
];

export const getFilterConfig = (ratings, hideListingFilter = false) => {
  let ratingOptions = [];
  if (ratings) {
    ratingOptions = ratings.rating.map((rating) => ({
      ...rating,
      label: rating.title,
      value: rating.id,
    }));
  }
  return [
    ...(!hideListingFilter
      ? [
          {
            source: "listing__title",
            placeholder: module_constants.listing,
            type: "input",
          },
        ]
      : []),
    {
      source: "rating",
      placeholder: module_constants.rating,
      type: "dropdown",
      options: ratingOptions,
      disableContains: true,
    },
  ];
};
