import { api } from "data";
import { TABS } from "../data/module_constants";
import { section_key } from "features/AdminPanel/constants/AdminPanel.constants";
import {
  FEEDBACK_BACK_NAVIGATE_PARAM,
  FEEDBACK_SECTION_KEY,
} from "features/ManageOfferings/modules/OfferingFeedback/constants/OfferingFeedback.constants";

export const tableChipsFormatter = (ratings, listings) => (filter, value) => {
  switch (filter) {
    case "rating": {
      const rating = ratings?.rating.find((rating) => rating.id == value);
      return `Rating: ${rating?.title}`;
    }
    case "listing__title__icontains":
    case "title__icontains": {
      const offering = listings?.find((offering) => offering.title == value);
      return offering ? `Offering: ${offering?.title}` : null;
    }
    default:
      return undefined;
  }
};

export const getTabConfig = ({
  listingUuid = "",
  navigateBack = false,
} = {}) => {
  let userFeedbacksPath, feedbackFormsPath, publishedFeedbackPath;

  if (listingUuid) {
    const baseParams = `${section_key}=${FEEDBACK_SECTION_KEY}`;
    const navigateParam = navigateBack
      ? `&${FEEDBACK_BACK_NAVIGATE_PARAM}=${navigateBack}`
      : "";

    userFeedbacksPath = `/offering-user-feedbacks/${listingUuid}?${baseParams}${navigateParam}`;
    feedbackFormsPath = `/offering-feedback-form/${listingUuid}?${baseParams}${navigateParam}`;
    publishedFeedbackPath = `/offering-published-form/${listingUuid}?${baseParams}${navigateParam}`;
  } else {
    userFeedbacksPath = `/${api.feedbacks.fetch_feedbacks_list}`;
    feedbackFormsPath = `/${api.feedbacks.fetch_listing_feedbacks}`;
    publishedFeedbackPath = `/${api.feedbacks.fetch_published_feedbacks}`;
  }

  return [
    {
      label: TABS["user-feedbacks"].label,
      value: TABS["user-feedbacks"].value,
      path: userFeedbacksPath,
    },
    {
      label: TABS["manage-feedback-forms"].label,
      value: TABS["manage-feedback-forms"].value,
      path: feedbackFormsPath,
    },
    {
      label: TABS["published-feedbacks"].label,
      value: TABS["published-feedbacks"].value,
      path: publishedFeedbackPath,
    },
  ];
};
