import React from "react";
import constant from "constants/constants";
import formConstants from "./constants";
import { QuestionsTypeField } from "common/Components/TableFields/TableFields";
import ActionFieldCustom from "ui/Fields/ActionFieldCustom";
import MoreActions from "./components/MoreActions";

const scheduleTypes = [];
constant.schedule_type.forEach((type) => {
  scheduleTypes.push({
    label: type.name,
    source: "listing__type",
    filterValue: type.id,
    value: type.id,
  });
});
export const tabFilterConfig = scheduleTypes;

export const getTableConfig = () => [
  {
    field: formConstants.form_data.question,
    headerName: formConstants.form_header.question,
    columnClassName: "word_break",
    isDrawerTitle: true,
    emptyField: "N/A",
    sortable: false,
    width: "525px",
  },
  {
    field: formConstants.form_data.type,
    headerName: formConstants.form_header.type,
    emptyField: "N/A",
    sortable: false,

    valueFormatter: (record) => (
      <QuestionsTypeField
        record={record}
        source={formConstants.form_data.type}
      />
    ),
    columnClassName: "word_break",
    width: "250px",
  },
  {
    field: "",
    headerName: formConstants.form_header.action,

    valueFormatter: (record) => (
      <ActionFieldCustom
        record={record}
        label={formConstants.action}
        ActionField={MoreActions}
        Nobutton={true}
      />
    ),
    width: "140px",

    isCollapsed: true,
  },
];
