import ExlyTable from "common/Components/ExlyTable";
import React from "react";
import StatsComponent from "../../components/StatsComponent";
import {
  EXLY_FEEDBACKS_MORE_HREFS,
  published_feedbacks,
  TABS,
} from "../../data/module_constants";
import { useFeedbacks } from "../../data/useFeedbacks";
import {
  getFilterConfig,
  getTableConfig,
  tableChipsFormatter,
} from "./TableConfig";
import { Button } from "@my-scoot/component-library-legacy";
import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ReviewForm from "../../components/PublishedFeedbacks/ReviewForm";
import { useDispatch, useSelector } from "react-redux";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import classnames from "classnames";
import module_style from "../../Style.module.css";
import { is_empty } from "utils/validations";
import {
  RenderCustomerRating,
  RenderReviewStatus,
} from "../../components/TableFields/TableFields";
import useTabsConfig from "../../data/useTabsConfig";
import { useListController } from "react-admin";
import { EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE } from "../../redux/actions";
import { OFFERING_STATUSES } from "constants/schedule";
import { useOfferings } from "hooks/useOfferings";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { OfferingFeedbackSettingsBtn } from "features/ManageOfferings/modules/OfferingFeedback/modules/OfferingFeedbackSettingsBtn/OfferingFeedbackSettingsBtn";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useParams } from "react-router-dom";

const PublishedFeedbacks = (props) => {
  const {
    layoutDesktopMargin,
    titleClassname,
    hideFilters,
    showSettings,
    isManageOfferings,
  } = props;
  const { fetchOfferings } = useOfferings();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const {
    getReviewModalProps,
    show_modal,
    handleModalSave,
    MORE_ACTIONS,
    handleModalOpen,
    handleReviewDelete,
    updateData,
    show_delete_modal,
    handleModalClose,
    getFeedbackQuestions,
    filter_offerings,
    empty_state_props,
  } = useFeedbacks(props);
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.FEEDBACKS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });
  const { tabConfig } = useTabsConfig({
    ...props,
    active_tab: TABS["published-feedbacks"].value,
  });
  const { listing_uuid } = useParams();
  const { filterValues } = useListController(props);

  const published_review_data = useSelector(
    (state) => state.exlyFeedback.published_reviews
  );
  const payload = published_review_data.review_payload;
  const show_offerings = published_review_data.show_offerings;

  const [review_modal_props, set_review_modal_props] = React.useState(false);

  React.useEffect(() => {
    if (!show_offerings && review_modal_props.showSecondaryBtn) return;
    set_review_modal_props(getReviewModalProps());
  }, [show_offerings, payload]);

  React.useEffect(() => {
    fetchOfferings(OFFERING_STATUSES.live.key);
  }, []);

  React.useEffect(() => {
    if (!is_empty(filterValues?.listing__uuid)) {
      updateData(filterValues?.listing__uuid, "listing_uuid");
    } else {
      updateData("", "listing_uuid");
    }
    dispatch({
      type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
      payload: {
        key: "show_offerings",
        value: is_empty(filterValues?.listing__uuid),
      },
    });
  }, [filterValues]);

  return (
    <>
      <ExlyTable
        ra_props={props}
        hasMobileFooter={true}
        tabConfig={tabConfig}
        {...(!showSettings && { tableTitle: "Feedback" })}
        selectedTab={TABS["published-feedbacks"].value}
        columnConfig={getTableConfig()}
        {...(!hideFilters
          ? { tableFilters: getFilterConfig(filter_offerings) }
          : {})}
        tableChipsFormatter={tableChipsFormatter(filter_offerings)}
        tableActionComponent={
          <Button
            onClick={() =>
              handleModalOpen({ ...filterValues, listing__uuid: listing_uuid })
            }
            size="thin"
            color="primary"
            className={`${hideFilters && " mt-n3 mb-3"}`}
          >
            {published_feedbacks.add_custom_feedback}
          </Button>
        }
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: showSettings ? "210px" : "120px",
          layoutDesktopMargin: layoutDesktopMargin ?? "20px 24px",
          noMobileBoxShadow: true,
          headerClassName: "mb-1",
          titleClassname,
          description: (
            <>
              Host events and receive feedback from your customers. All the
              collected feedback will be listed here.
              <StatsComponent />
            </>
          ),
          learnMoreHref: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK,
          secondaryComponent:
            isDesktop && !isManageOfferings ? (
              <LearnMoreCta href={EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK} />
            ) : null,
          descriptionClassName: "w-100",
        }}
        footerProps={{
          primarybtn: published_feedbacks.add_custom_feedback,
          primaryClick: () => {
            handleModalOpen({ ...filterValues, listing__uuid: listing_uuid });
          },
          ...(showSettings && {
            classes: {
              footer: "px-3 pt-3 tw-gap-[16px] !tw-h-auto",
              mobileWrapper: " !tw-bottom-[99px]",
            },
            primaryBtnProps: {
              className: "!tw-h-[42px] !tw-whitespace-nowrap",
            },
            showSecondaryBtn: true,
            secondaryBtnJSX: (
              <OfferingFeedbackSettingsBtn
                buttonWrapperClassName="tw-flex-1"
                fullWidth
              />
            ),
          }),
        }}
        renderPrimaryColumnRightNode={(record) => (
          <RenderReviewStatus record={record} source="status" />
        )}
        borderedFields={true}
        fieldsLeftPadded={true}
        fieldsAlignment="space-between"
        recordFooterVariant="secondary"
        primaryActions={MORE_ACTIONS}
        getRecordFooterClassName={(record) =>
          classnames(
            module_style.mobile_footer,
            record.rating &&
              module_style[`mobile_rating_${parseInt(record.rating)}`]
          )
        }
        renderSecondaryAction={(record) => {
          let color = "#6c5dd3";
          if (!is_empty(record.rating)) {
            const overall_rating = record.rating;
            const feedback_questions = getFeedbackQuestions();
            const ratings = feedback_questions?.data?.data?.rating;
            color = !is_empty(ratings)
              ? ratings[parseInt(overall_rating) - 1]?.colour
              : "";
          }

          return (
            <div
              style={{ color: color }}
              className={`row no-gutters align-items-center ${module_style.mobile_secondary_action}`}
            >
              <span style={{ fontSize: 14 }}>Rating:</span>{" "}
              <RenderCustomerRating record={record} source="rating" />
            </div>
          );
        }}
        customPrimaryAction={{
          label: "Actions",
          onClick: (row, _rows, _setDrawerConfig, setActionsDrawerConfig) => {
            setActionsDrawerConfig({ row, open: true });
          },
          onClose: (_row, _rows, _setDrawerConfig, setActionsDrawerConfig) => {
            setActionsDrawerConfig({ row: null, open: false });
          },
        }}
        primaryKey="id"
        noExportButton
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: empty_state_props.content_title,
          description: empty_state_props.children,
          primaryCtaProps: isDesktop && {
            title: empty_state_props.primary_cta.children,
            onClick: () =>
              empty_state_props.primary_cta.onClick({
                ...filterValues,
                listing__uuid: listing_uuid,
              }),
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK,
          },
          className: "mt-3",
          hideActionsOnMobile: false,
          videoSrc: EXLY_FEEDBACKS_MORE_HREFS.FEEDBACK_VIDEO,
          playerProps: { playing: !isFeatureFlagLoading && !isFeatureVisited }, // autoplay the knowledge base video on the first visit to this feature
        }}
      />

      <ExlyModal
        padded={false}
        onPrimaryBtnClick={() => handleModalSave()}
        onSecondaryBtnClick={() => handleModalSave(true)}
        open={show_modal}
        {...review_modal_props}
      >
        <ReviewForm />
      </ExlyModal>

      <ExlyDeleteModal
        title="Confirm Delete"
        description="Are you sure you want to delete a selected review? This action will delete it from the system, and remove it from your offering page."
        onDelete={handleReviewDelete}
        open={show_delete_modal}
        onClose={handleModalClose}
      />
    </>
  );
};

export default withComponentLibraryTheme(PublishedFeedbacks);
