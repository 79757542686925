import React from "react";
import { is_empty } from "utils/validations";
import { getTabConfig } from "../common/tableConfig";
import { useLocation, useParams } from "react-router-dom";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import { FEEDBACK_BACK_NAVIGATE_PARAM } from "features/ManageOfferings/modules/OfferingFeedback/constants/OfferingFeedback.constants";

const useTabsConfig = () => {
  const { listing_uuid } = useParams();
  const navigateBack = getRAUrlParams().get(FEEDBACK_BACK_NAVIGATE_PARAM);
  const [tabConfig, setTabConfig] = React.useState(getTabConfig());
  const location = useLocation();

  React.useEffect(() => {
    if (!is_empty(listing_uuid)) {
      // Set the filters for React-Admin
      // setFilters({ listing__uuid: listing_uuid });
      setTabConfig(getTabConfig({ listingUuid: listing_uuid, navigateBack }));
    }
  }, [location]);

  return {
    tabConfig,
  };
};

export default useTabsConfig;
