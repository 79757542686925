import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useListController, useRefresh } from "react-admin";
import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import qs from "query-string";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyTable from "common/Components/ExlyTable";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import {
  filterConfig,
  getTableConfig,
  tableChipsFormatter,
} from "./OrgMembers.tableConfig";
import { orgLabels, tabConfig } from "../../Org.constants";
import style from "../../Org.module.css";
import { orgPermissions } from "utils/OrgPermissions";
import OrgMemberCards from "../../components/OrgMemberCards";
import { is_empty } from "utils/validations";
import { useNotifications } from "utils/hooks";
import {
  ActiveStatus,
  ContactDetails,
} from "../../components/TableFields/TableFields";
import { updateStaffMemberDetails, deleteMember } from "../../Org.helpers";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { ORG_MEMBERS_LEARN_MORE_HREFS } from "./constants/OrgMembers.constants";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import { OrgMemberCredits } from "features/Crm/modules/OrgMemberCredits/OrgMemberCredits";
import { useCreditDetails } from "features/MySubscriptions/utils/useCreditDetails";
import {
  LICENSE_TYPES,
  SUBSCRIPTIONS_API_KEYS,
} from "features/MySubscriptions/constants/MySubscriptions.constants";

const OrgMembers = (props) => {
  const refresh = useRefresh();
  const history = useHistory();
  const { filterValues, loaded, data, loading } = useListController(props);
  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);
  const isDesktop = useDesktopMediaQuery();
  const HAS_WRITE_ACCESS =
    orgPermissions.isMainUser() || orgPermissions.isOwner();
  const showAddMember = HAS_WRITE_ACCESS && !(isEmpty || loading);
  const { notify } = useNotifications();
  const [memberToBeDeleted, setMemberToBeDeleted] = useState(null);

  const openMemberProfile = useCallback(({ record }) => {
    history.push(`/organization/member/profile/${record.org_uuid}/schedule`);
  }, []);

  const tableConfig = useMemo(() => {
    return getTableConfig({ openMemberProfile });
  }, []);

  const addMember = useCallback((role) => {
    if (!role) {
      history.push("/organization/member/add");
      return;
    }
    const query = { role };
    const searchString = qs.stringify(query);
    history.push({
      pathname: "/organization/member/add",
      search: searchString,
    });
  }, []);

  const editMember = useCallback((memberId) => {
    history.push(`/organization/member/edit/${memberId}`);
  }, []);

  useEffect(() => {
    document.title = orgLabels.document_title;
  }, []);

  useAppLayoutControl({
    values: {
      learnMoreHref: ORG_MEMBERS_LEARN_MORE_HREFS.ORG_MEMBERS,
    },
  });

  const staffMemberCreditsData = useCreditDetails({
    params: {
      [SUBSCRIPTIONS_API_KEYS.feature_key]: LICENSE_TYPES.STAFF_MEMBERS,
    },
  });
  const { refetch: refetchStaffMemberCredits } = staffMemberCreditsData;

  const layoutProps = useMemo(
    () => ({
      paddingDesktop: "0",
      paddingBottom: showAddMember ? "68px" : "16px",
      noMobileBoxShadow: true,
      noBoxShadow: isEmpty,
      secondaryComponent: isDesktop && (
        <div className="d-flex align-items-center">
          <LearnMoreCta href={ORG_MEMBERS_LEARN_MORE_HREFS.ORG_MEMBERS} />
          {showAddMember && (
            <Button
              className={classnames(style.addMemberButton, "ml-2")}
              onClick={() => addMember()}
            >
              Add Member
            </Button>
          )}
        </div>
      ),
      descriptionClassName: "!tw-w-full !tw-block !tw-mt-5",
      description: (
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-flex-wrap">
          <div className="tw-max-w-[65%] max-md:tw-max-w-none">
            {orgLabels.page_description}
          </div>

          <OrgMemberCredits {...staffMemberCreditsData} />
        </div>
      ),
    }),
    [showAddMember, isEmpty, isDesktop, staffMemberCreditsData]
  );

  const footerProps = useMemo(
    () => ({
      primarybtn: orgLabels.add_member,
      primaryClick: () => addMember(),
      hidePrimarybtn: !showAddMember,
    }),
    [showAddMember]
  );

  const _deleteMember = useCallback((memberInfo) => {
    deleteMember(memberInfo)
      .then(() => {
        notify("Member has been deleted!", "success");
        setMemberToBeDeleted(null);
        refresh();
        refetchStaffMemberCredits();
      })
      .catch((err) => {
        notify(err.message || "Error while deleting member", "error");
      });
  }, []);

  const toggleMemberActiveState = useCallback((memberInfo) => {
    const { active } = memberInfo || {};

    updateStaffMemberDetails(memberInfo, { is_active: !active })
      .then(() => {
        notify("Member's active state updated successfully!", "success");
        refresh();
        refetchStaffMemberCredits();
      })
      .catch((err) => {
        notify(err.message, "error");
      });
  }, []);

  const primaryActions = useMemo(() => {
    const actions = [
      {
        label: "View profile/activity",
        onClick: (row, rows, setDrawerConfig, setActionsDrawerConfig) => {
          if (setActionsDrawerConfig)
            setActionsDrawerConfig({ row: null, open: false });
          openMemberProfile({ record: row });
        },
        visible: true,
      },
      {
        label: "Edit Member Details",
        onClick: (row, rows, setDrawerConfig, setActionsDrawerConfig) => {
          if (setActionsDrawerConfig)
            setActionsDrawerConfig({ row: null, open: false });
          editMember(row.org_uuid);
        },
        visible: HAS_WRITE_ACCESS,
      },
      {
        label: "Delete member",
        onClick: (row, rows, setDrawerConfig, setActionsDrawerConfig) => {
          if (setActionsDrawerConfig)
            setActionsDrawerConfig({ row: null, open: false });
          setMemberToBeDeleted(row);
        },
        visible: HAS_WRITE_ACCESS,
      },
      {
        renderCustomAction: (
          row,
          rows,
          setDrawerConfig,
          setActionsDrawerConfig
        ) => {
          const { active } = row || {};
          return (
            <span
              className={!isDesktop && style.mobileAction}
              onClick={() => {
                toggleMemberActiveState(row);
                if (!isDesktop && setActionsDrawerConfig)
                  setActionsDrawerConfig({ row: null, open: false });
              }}
            >
              {active ? "Mark as inactive" : "Mark as active"}
            </span>
          );
        },
        visible: HAS_WRITE_ACCESS,
      },
    ];
    return actions.filter((item) => item.visible);
  }, [isDesktop, HAS_WRITE_ACCESS]);

  return (
    <div className={classnames(isDesktop && "component-wrapper")}>
      <ExlyTable
        tableTitle={orgLabels.page_title}
        ra_props={props}
        customEmptyState={<OrgMemberCards addMember={addMember} />}
        noExportButton={true}
        tabConfig={tabConfig}
        selectedTab="add-members"
        layoutProps={layoutProps}
        columnConfig={tableConfig}
        tableFilters={filterConfig}
        tableChipsFormatter={tableChipsFormatter}
        borderedFields
        fieldsAlignment="space-between"
        fieldsLeftPadded
        drawerFieldsBordered
        primaryKey="id"
        hasMobileFooter={showAddMember}
        footerProps={footerProps}
        renderPrimaryColumnRightNode={(record) => (
          <ContactDetails record={record} />
        )}
        primaryActions={primaryActions}
        recordFooterVariant="secondary"
        renderSecondaryAction={(record) => (
          <div className="d-flex">
            <span className="mr-2 font_600">Status:</span>
            <ActiveStatus record={record} />
          </div>
        )}
        primaryColumnProps={{
          align: "center",
          sticky: true,
          width: primaryActions.length === 1 ? "200px" : "50px",
          title: "Action",
        }}
        customPrimaryAction={
          primaryActions.length === 1
            ? null
            : {
                label: "Action",
                onClick: (
                  row,
                  rows,
                  setDrawerConfig,
                  setActionsDrawerConfig
                ) => {
                  setActionsDrawerConfig({ row, open: true });
                },
                onClose: (
                  row,
                  rows,
                  setDrawerConfig,
                  setActionsDrawerConfig
                ) => {
                  setActionsDrawerConfig({ row: null, open: false });
                },
              }
        }
      />
      {memberToBeDeleted && (
        <ExlyDeleteModal
          open={memberToBeDeleted}
          onClose={() => setMemberToBeDeleted(null)}
          onDelete={() => _deleteMember(memberToBeDeleted)}
          title="Confirm Delete"
          description="Are you sure you want to delete the member?"
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(OrgMembers);
